/*
    Created on : 18-Dec-2017, 7:19:44 AM
    Author     : Reaper
*/

footer {
    width: 100%;
    a {
      color: white;
      text-decoration: none;
    }
    #partners {
        border-bottom: solid 1px white;
        padding: 10px 0 10px;
        img {
            height: 100%;
            max-height: 60px;
        }
    }
    .logo {
        width: 250px;
    }
    h5 {
      font-size: 20px;
    }
}

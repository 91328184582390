/* 
    Created on : 23-Jun-2018, 12:21:24 PM
    Author     : Kevin
*/
/* Image Slider */
#imageslider {
	color: #fff;
	background-size: cover;
}
.slideroverlay {
	background-color: rgba(66, 75, 80, 0.91);
	height: 100%;
	width: 100%;
}
#imageslider .rowmargin {
	margin-top:70px;
}
#imageslider h1 {
	font-size: 50px;
	font-weight: 600;
	padding-bottom: 15px;
	text-transform: uppercase;
	color: #F1F1F1;
	text-shadow: 0px 3px 3px #2B2B2B;
}
#imageslider h3 {
	font-size: 24px;
	font-weight: 300;
	color: #FFFFFF;
	padding-top: 15px;
	padding-bottom: 15px;
	text-shadow: 0px 3px 3px #2B2B2B;
	border-top: 1px solid #545454;
	border-bottom: 1px solid #545454;
	margin-bottom: 30px;
}
#imageslider h4 {
	font-size: 16px;
	font-weight: 300;
	color: #FFFFFF;
	padding-top: 0px;
	padding-bottom: 0px;
	text-shadow: 0px 3px 3px #2B2B2B;
}
#imageslider p {
	margin-top:30px;
	margin-bottom:30px
}
#imageslider p.slidebutton a {
	margin-right:30px;
}
#imageslider p.slidebutton .fa {
	color:#fff;
}
#imageslider .sliderbutton {
	margin-top: 30px;
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 2px;
}
#imageslider .carousel-indicators {
	bottom: -75px;
}
#imageslider form {
	margin-top: 100px;
}
#imageslider .fa {
	color: #6CA93E;
	vertical-align: baseline;
	margin-top: 8px;
	margin-bottom: 8px;
	padding-right: 10px;
}
#imageslider #slide1 {
	background-image:url(../img/slide1.png);
}
#imageslider #slide2 {
	background-image:url(../img/slide2.png);
}
#imageslider #slide3 {
	background-image:url(../img/slide3.png);
}
#imageslider .carousel-control .icon-next, #imageslider .carousel-control .icon-prev {
	font-size: 80px !important;
}
#imagesliderdomains {
	margin-top: -115px;
	height: 115px;
}
#imagesliderdomains .sliderbutton {
	margin-top: 30px;
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 2px;
}
#imagesliderdomains .input-group {
	padding-bottom:30px;
}
#imagesliderdomains #domainsearch {
	font-size:27px;
	height:60px;
	padding-left:2px;
	border-left:0;
	color:#333;
	box-shadow:0 0
}
#imagesliderdomains .domaincheckselect {
	-moz-appearance:none;
	text-indent:.01px;
	text-overflow:'';
	width:145px;
	height:60px;
	border-radius:0 !important;
	border:none;
	font-size:27px;
	-webkit-appearance:none;
	-webkit-border-radius:0;
	border-top:1px solid #ccc !important;
	border-bottom:1px solid #ccc !important;
	outline:0;
	box-shadow:0 0;
	background-image:url(../img/down-arrow.png);
	background-repeat:no-repeat;
	background-position-x:80%;
	background-position-y:55%;
	background-position:80% 55%
}
#imagesliderdomains .domaincheckselect:hover {
	background-image:url(../img/down-arrow-hover.png);
}
#imagesliderdomains .form-control {
	-moz-appearance: none;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
}
#imagesliderdomains .form-control:focus {
	height:60px;
	border-radius:0 !important;
	border:none;
	font-size:27px;
	-webkit-appearance:none;
	-webkit-border-radius:0;
	border-top:1px solid #ccc !important;
	border-bottom:1px solid #ccc !important;
	border-color:#ccc;
	outline:0;
	box-shadow:0 0
}
#imagesliderdomains .domainsearchprefix {
	text-align:center;
	font-size:27px;
	height:60px;
	border-right:0;
	padding-right:0;
	color:#6F6F6F
}
#imagesliderdomains .btn-default[disabled] {
	background:#FFF;
	color:#6F6F6F !important;
	cursor:pointer !important;
	opacity:1 !important
}
#imagesliderdomains .domainsearchdomainname {
	max-width:918px;
	border-right:0
}
#imagesliderdomains .domainsearcharrow {
	color:#797C6F !important
}
#imagesliderdomains .domainsearchsubmit {
	font-size:23px;
	color:#fff;
	background: #6CA93E;
	border: 1px solid #6CA93E;
	width:140px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	padding: 13px;
}
#imagesliderdomains .domainsearchsubmit:hover {
	opacity: 0.9;
}
.carousel, .item, .active {
	height: 100%;
}
.carousel-inner {
	height: 100%;
}
/* Background images are set within the HTML using inline CSS, not here */

.fill {
	width: 100%;
	height: 100%;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}
.carousel-caption {
	top: 100px;
}

/*
.carousel-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  
  width: 100%;
  min-height: 25em;
}
.slide {
  flex: 0 0 100%;
  height: 20em;
  margin: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}
.slide:first-of-type {
  opacity: 0;
}
.slide:last-of-type {
  opacity: 0;
}*/
/*
    Created on : 16-Dec-2017, 8:29:00 PM
    Author     : Reaper
*/

header {
    width: 100%;
    height: $header-height;
    #logo {
        max-width: 235px;
        img {
            max-width: 235px;
            max-height: 60px;
            height: 100%;
        }
    }
    li {
      list-style: none;
    }
    nav {
        float: right;
        height: $header-height;
        > ul {
            height: $header-height;
            > li {
                display: inline-block;
                line-height: 20px;
                a {
                    text-decoration: none;

                }
            }

            li.dropdown_trigger:hover {
                > ul.dropdown {
                    display: block;
                }
            }
            ul.dropdown {
              margin-top: -2px;
              padding: 0;
              border-radius: 7px;
              display: none;
              z-index: 9999;
              min-width: 150px;
            }
            > li > ul.dropdown {
                > li {
                  line-height: 16px;
                    > ul.dropdown {
                        padding: 0;
                        position: absolute;
                        top: 80px;
                        left: 160px;
                    }
                }
                position: absolute;
                top: $header-height;
            }
        }
    }
}

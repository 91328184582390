/* 
    Created on : 22-Jun-2018, 4:29:30 PM
    Author     : Kevin Roy
*/

fieldset {
    padding: 5px 0;
    input {
        padding: 5px;
    }
}


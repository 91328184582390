/* 
    Created on : 16-Dec-2017, 8:29:10 PM
    Author     : Reaper
*/

/* Font folder */
$fa-font-path: '../fonts';


/* Heights */
$header-height: 60px;
$footer-height: 200px;
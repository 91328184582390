/*
    Created on : 16-Dec-2017, 8:32:24 PM
    Author     : Reaper
*/

body {
    font-family: 'Kanit';
    background: url('../img/bg2.png');
    background-size: 100% 100%;
    background-attachment: fixed;
}
#app {
  min-height: 100vh;
}
.wrapper-border {
    border: solid 2px black;
    box-shadow: 5px 5px 5px black;
    border-radius: 7px;
    background-color:rgba(96, 111, 123, 0.9);
}
ul.clientarea {
  list-style: none;
  text-decoration: none;
}
.router-link-exact-active,
.router-link-active {
  font-weight:700;
}

h4 {
    margin: 10px 0;
}

main#home {
    min-height: 400px;
    margin-top: -5px;
    #featured {
        .lg\:w-1\/3 {
            vertical-align: top;
        }
        .wrapper-border {
           // margin: 15px;
        }
        .top-icon {
            max-height: 75px;
        }
        .price {
            font-size: 4rem;
            sup, small {
                font-size: 2rem;
            }
        }
        .package {
            font-size: 20px;

        }
        .specs {
            margin: 15px 0;
            list-style: none;
            text-align: left;
        }
    }
    #website-tools {
        .tool-icon {
            max-height: 120px;
        }
    }
    #announcementsnewsfeed ul {
        list-style: none;
    }
    #textboxes {
        margin: 50px 0 0;
        h3 {
            font-size: 1.5rem;
        }
    }
    #productfeature {
        h3 {
            margin-bottom: 30px;
            font-size: 30px;
        }
        p {
            margin-bottom: 30px;
        }
    }
}

main#website_hosting,
main#reseller_hosting {

  h2 {
    font-size: 30px;
    margin-bottom: 15px;
  }
   p {
    margin-bottom: 5px;
    font-size: 20px;
  }
  #hostingfeatures .feature {
    //height: 47.45vh;
  }
}
